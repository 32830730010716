<template>
    <div>
        EVENTS
    </div>
</template>

<script>
// import { mapGetters } from 'vuex'
export default {
  props: [],
  data () {
    return {}
  },
  computed: {},
  methods: {},
  watch: {},
  mounted () {},
  components: {}
}
</script>
